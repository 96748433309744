<template>
  <span
    :class="{
      'has-text-warning': overTreshold,
    }"
  >
    {{ row[field] | percentage(2) }}
  </span>
</template>

<script>
import BaseColumn from "./BaseColumn";

export default {
  extends: BaseColumn,
  computed: {
    overTreshold() {
      if (this.field !== "averageScaling") {
        return false;
      }

      let treshold = this.settings.lengthScalingTreshold;
      if (treshold === null) {
        return false;
      }

      return this.row[this.field] >= treshold;
    },
  },
};
</script>
